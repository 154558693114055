import React from 'react';
import './Feedback.css';
import { useTranslation } from 'react-i18next';

const Feedback: React.FC = () => {
    
    const { t, i18n } = useTranslation();
  
    const surveyUrlEn = process.env.REACT_APP_surveyUrlEn?.toString().replace(/{AND}/g,"&");
    const surveyUrlFr = process.env.REACT_APP_surveyUrlFr?.toString().replace(/{AND}/g,"&");

    const getLinkUrl = () => {
        return i18n.resolvedLanguage === 'en'
        ? surveyUrlEn
        : surveyUrlFr;
    };
    return (
    <a 
        target="_blank" 
        title={t("feedbackTitle")} 
        aria-label={t("feedbackTitle")}
        href={getLinkUrl()} 
        className="feedbackButton"
        rel="noopener noreferrer"
    >
        <div className="feedbackLabel">{t("feedback")}</div>
    </a>
    );
};

export default Feedback;