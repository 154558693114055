import React, { useState, useContext, useEffect } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import { getConfirmFileList, getToken, retrieveOid, StepComponentProps } from '../Helper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { UncdContext } from "./StateProvider"
import { useMsal } from "@azure/msal-react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import { AnalyticsLink, LinkKey } from '../services/AnalyticsLink';
import Link from '@mui/material/Link';
import './Review.css';

const Review: React.FC<StepComponentProps> = (props) => {
    const { instance } = useMsal();
    // gray out the screen and disable all ui elements if submission in progress
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [noAcceptTermOfUseError, setNoAcceptTermOfUseError] = useState(false);

    const { t, i18n } = useTranslation();
    const ctx = useContext(UncdContext);
    const analyticsLink = new AnalyticsLink();
    
    //google anlaytics
    if (ctx.isUncd) {
        analyticsLink.sendLinkAnalyticsReport(LinkKey.review, i18n.resolvedLanguage);
    } else {
        analyticsLink.sendLinkAnalyticsReport(LinkKey.edobReview, i18n.resolvedLanguage); 
    }

    const submitFileUrl = ctx.isUncd ? process.env.REACT_APP_submitFileUrl : process.env.REACT_APP_edobSubmitFileUrl;

    useEffect(() => {
        window.scrollTo(0, 0);
        window.history.pushState('','','/reviewyoursubmission');
        document.title = ctx.isUncd ? t("reviewTitle") : t("edobReviewTitle");
    }, [t, ctx.isUncd]);
    
    const getEmailAddress = () => {
        const account = instance.getActiveAccount();
        return account?.username;
    }

    const onSendConfirmationChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        ctx.setSendConfirmationEmail(event.target.checked);
    };

    const onAcceptTermChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        ctx.setAcceptTermOfUse(event.target.checked);
        setNoAcceptTermOfUseError(!event.target.checked);
        if (event.target.checked && !ctx.isUncd) {
            analyticsLink.sendLinkAnalyticsReport(LinkKey.edobDeclaration, i18n.resolvedLanguage);
        }
    };


    const prepareHeaders = (token: string): any => {
        const headersConfirm: any = {};
        headersConfirm['Content-Type'] = "application/json";

        if (token !== '') {
            headersConfirm['authorization'] = token;
        }


        return headersConfirm;
    }

    const clearError = () => {
        setNoAcceptTermOfUseError(false);
    }

    const onSubmit = () => {
        return new Promise(resolve => setTimeout(() => {
            setNoAcceptTermOfUseError(!ctx.acceptTermOfUse);
        }, 100));
    }
    const onNext = async () => {
        clearError();
        setSubmitClicked(true);
        if (!ctx.isUncd) {
            analyticsLink.sendLinkAnalyticsReport(LinkKey.edobReviewSubmit, i18n.resolvedLanguage);
        }
        if (ctx.acceptTermOfUse) {
            if (!submitInProgress) {
                setSubmitInProgress(true);
                const token = await getToken(instance);
                let authToken = 'Bearer ' + token;
                const headers = prepareHeaders(authToken);
                const isUncd = ctx.isUncd;
                const resBody: any = {
                    //subFileInfo: getConfirmFileList(ctx.supportDocumentUploadQueue),
                    //userLoginID: retrieveOid(token),
                    fileIds: getConfirmFileList(ctx.documentUploadQueue, ctx.isWsib, isUncd),
                    email: getEmailAddress(),
                    sendEmailConfirmation: ctx.sendConfirmationEmail,
                    language: i18n.resolvedLanguage,
                    dateOfBirth: ctx.seletedBirthDate + '/' + ctx.seletedBirthMonthNumStr + '/' + ctx.seletedBirthYear,
                }
                resBody.userLoginID = retrieveOid(token);
                resBody.claimantLastName = ctx.lastnameClaimant.trim();
                if (!ctx.isUncd) {
                    resBody.claimNumber = ctx.claimnumberClaimant;
                } else {
                    resBody.claimantFirstName = ctx.firstnameClaimant.trim();
                    resBody.subFileInfo = getConfirmFileList(ctx.supportDocumentUploadQueue, true, true);
                    // date of accident is optional
                    if (ctx.seletedAccidentYear !== '') {
                        resBody.dateOfAccident = ctx.seletedAccidentDate + '/' + ctx.seletedAccidentMonthNumStr + '/' + ctx.seletedAccidentYear;
                    } else {
                        resBody.dateOfAccident = '';
                    }
                }

                const strBody = JSON.stringify(resBody);

                fetch(submitFileUrl!, {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: headers,
                    body: strBody,
                }).then((result) => {
                    ctx.setUploadSubmitTime(new Date());
                    props.onValidated();
                    // TODO: disable user to navigate back or make all page readonly
                }).catch((err) => {
                    console.log(err);
                    //set server error
                    // google analytics
                    analyticsLink.sendLinkAnalyticsReport(LinkKey.technical, i18n.resolvedLanguage);
                    setServerError(true);
                }).finally(() => {
                    setSubmitInProgress(false);
                });
            }
        } else {
            await onSubmit();
        }
    };

    return (
        <LocalizationProvider dateAdapter={DateFnsAdapter} >
            <Container>
                <Grid container role="region" rowSpacing={1} aria-labelledby="region-review">
                    <Grid item xs={12}>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={submitInProgress}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Grid>

                    <Grid item xs={12} className="row">
                        {ctx.isUncd && <div><Typography variant="h1" id="region-review">{t("label.review") + ' (' + t('uncdReviewStepLabel') + ')'}</Typography></div>}
                        {!ctx.isUncd && <div><Typography variant="h1" id="region-review">{t("label.review") + ' (' + t('edobReviewStepLabel') + ')'}</Typography></div>}
                        {!ctx.isUncd && <div><Typography variant="h2"  style={{marginTop: 10}}>{t("label.claim.number")}{ctx.claimnumberClaimant}</Typography></div>}
                        <br role="presentation"/>
                    </Grid>
                    {serverError && <React.Fragment>
                        <Grid item xs={12} role="alert" >
                            <div><Typography variant="h2">{t('error_message_contact_wsib1')}</Typography></div>
                        </Grid>
                        <Grid item xs={12} role="alert" >
                            {t("error_message_contact_wsib2")}
                            {i18n.resolvedLanguage === 'en' && <a className="link" href="https://www.wsib.ca/en/contact-us"
                                target="_blank" rel="noopener noreferrer">
                                {t("error_message_contact_wsib3")}.</a>}
                            {i18n.resolvedLanguage === 'fr' && <a className="link" href="https://www.wsib.ca/fr/contactez-nous"
                                target="_blank" rel="noopener noreferrer">
                                {t("error_message_contact_wsib3")}.</a>}

                        </Grid>
                    </React.Fragment>}
                    {!serverError && ctx.isUncd && <Grid item xs={12}>
                        <Paper style={{ width: '95%', padding: 2 }} elevation={3}>
                            <Grid container style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }} rowSpacing={1}>
                                <Grid item xs={12} >
                                    <span style={{ marginRight: '20px' }}>{t("label.claimant.firstname")}</span>{ctx.firstnameClaimant.trim()}
                                </Grid>
                                <Grid item xs={12} >
                                    <span style={{ marginRight: '20px' }}>{t("label.claimant.lastname")}</span>{ctx.lastnameClaimant.trim()}
                                </Grid>
                                <Grid item xs={12} >
                                    <span style={{ marginRight: '20px' }}>{t("label.contact.emailaddress")}</span>{getEmailAddress()}
                                </Grid>
                                <Grid item xs={12} >
                                    {i18n.resolvedLanguage !== 'fr' && <React.Fragment>
                                        <span style={{ marginRight: '20px' }}>{t("label.claimant.dateofbirth.review")}</span>{ctx.birthdayClaimant?.toLocaleDateString('en',
                                            { year: 'numeric', month: 'long', day: 'numeric' })}
                                    </React.Fragment>}
                                    {i18n.resolvedLanguage === 'fr' && <React.Fragment>
                                        <span style={{ marginRight: '20px' }}>{t("label.claimant.dateofbirth.review")}</span>{ctx.birthdayClaimant?.toLocaleDateString('fr',
                                            { year: 'numeric', month: 'long', day: 'numeric' })}
                                    </React.Fragment>}
                                </Grid>
                                <Grid item xs={12} >
                                    {ctx.accidentDateClaimant && i18n.resolvedLanguage !== 'fr' && <React.Fragment>
                                        <span style={{ marginRight: '20px' }}>{t("label.claimant.dateofaccident.review")}</span>{ctx.accidentDateClaimant?.toLocaleDateString('en',
                                            { year: 'numeric', month: 'long', day: 'numeric' })}
                                    </React.Fragment>}
                                    {ctx.accidentDateClaimant&& i18n.resolvedLanguage === 'fr' && <React.Fragment>
                                        <span style={{ marginRight: '20px' }}>{t("label.claimant.dateofaccident.review")}</span>{ctx.accidentDateClaimant?.toLocaleDateString('fr',
                                            { year: 'numeric', month: 'long', day: 'numeric' })}
                                    </React.Fragment>}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    }

                    {!serverError && ctx.documentUploadQueue.length > 0 && <Grid item xs={12}>
                        <br role="presentation"/>
                            {ctx.documentUploadQueue.map((item, index) => {
                                if (!ctx.isUncd && item.isWsibForm === 'yes') {
                                    return (
                                        <Paper variant="outlined" key={index} style={{ width: '95%', padding: 2, backgroundColor: '#F6F8FA', marginBottom: '10px', color: '#333333' }} elevation={0}>
                                            <Grid container style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }} rowSpacing={1}>
                                                <Grid item xs={12} >
                                                    <p><strong>{t("lable.form.name")}</strong></p>
                                                    {i18n.resolvedLanguage === 'fr' ? <span >{item.edobForm?.formNameFr}</span> : <span>{item.edobForm?.formNameEn}</span>}
                                                </Grid> 
                                                <Grid item xs={12} >
                                                    <p><strong>{t("label.file")}</strong></p><span style={{ marginRight: '20px' }}>{item._file.name}</span>|
                                                    {item._file.size / 1024 / 1024 >= 1 && <label style={{ marginLeft: 20 }}>{Math.round(item._file.size / 1024 / 1024)} {t("file_size_mb")}</label>}
                                                    {item?._file?.size / 1024 / 1024 < 1 && <label style={{ marginLeft: 20 }}>{Math.round(item._file?.size / 1024)} {t('file_size_kb')}</label>}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    )
                                }
                                else if (!ctx.isUncd) {
                                    return (
                                        <Paper variant="outlined" key={index} style={{ width: '95%', padding: 2, backgroundColor: '#F6F8FA', marginBottom: '10px', color: '#333333' }} elevation={0}>
                                            <Grid container style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }} rowSpacing={1}>
                                                <Grid item xs={12} >
                                                    <p><strong>{t("lable.form.name")}</strong></p>
                                                    {i18n.resolvedLanguage === 'fr' ? <span >{item.subEdobForm?.formNameFr}</span> : <span>{item.subEdobForm?.formNameEn}</span>}
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <p><strong>{t("label.file")}</strong></p><span style={{ marginRight: '20px' }}>{item._file.name}</span>|
                                                    {item._file.size / 1024 / 1024 >= 1 && <label style={{ marginLeft: 20 }}>{Math.round(item._file.size / 1024 / 1024)} {t("file_size_mb")}</label>}
                                                    {item?._file?.size / 1024 / 1024 < 1 && <label style={{ marginLeft: 20 }}>{Math.round(item._file?.size / 1024)} {t('file_size_kb')}</label>}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    )
                                }
                                else {
                                    return (
                                        <Paper variant="outlined" key={index} style={{ width: '95%', padding: 2, backgroundColor: '#F6F8FA', color: '#333333' }} elevation={0}>
                                            <Grid container style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }} rowSpacing={1}>
                                                <Grid item xs={12} >
                                                    <span style={{ marginRight: '20px' }}>{t("label.file")}</span><span style={{ marginRight: '20px' }}>{ctx.documentUploadQueue[0]._file.name}</span>
                                                    {ctx.documentUploadQueue[0]._file.size / 1024 / 1024 >= 1 && <label style={{ marginLeft: 20 }}>{Math.round(ctx.documentUploadQueue[0]._file.size / 1024 / 1024)} {t("file_size_mb")}</label>}
                                                    {ctx.documentUploadQueue[0]?._file?.size / 1024 / 1024 < 1 && <label style={{ marginLeft: 20 }}>{Math.round(ctx.documentUploadQueue[0]._file?.size / 1024)} {t('file_size_kb')}</label>}
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <span style={{ marginRight: '20px' }}>{t("label.document-type")}</span>
                                                    {i18n.resolvedLanguage === 'fr' ? <span >{ctx.documentUploadQueue[0].wsibForm?.formNameFr}</span> : <span>{ctx.documentUploadQueue[0].wsibForm?.formNameEn}</span>}
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <span style={{ marginRight: '20px' }}>{t('message_has_support_file')}</span>
                                                    {ctx.hasSupportDoc === 'yes' ? <span>{t('message_dob_documentation_docfromYes')}</span> :
                                                        <span >{t('message_dob_documentation_docfromNo')}</span>}
                                                </Grid>

                                                {ctx.supportDocumentUploadQueue.map((supportDoc, index) => {
                                                    return <Grid item xs={12} key={"supportfile" + index.toString()}>
                                                        <Paper variant="outlined" style={{ width: '95%', padding: 3, backgroundColor: '#F6F8FA', color: '#333333' }} elevation={0}>
                                                            <Grid container style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }} rowSpacing={1}>
                                                                <Grid item xs={12} >
                                                                    <span style={{ marginRight: '20px' }}>{t("label.support.file")}</span><span style={{ marginRight: '20px' }}>{supportDoc._file.name}</span>
                                                                    {supportDoc._file.size / 1024 / 1024 >= 1 && <label style={{ marginLeft: 20 }}>{Math.round(supportDoc._file.size / 1024 / 1024)} {t("file_size_mb")}</label>}
                                                                    {ctx.documentUploadQueue[0]?._file?.size / 1024 / 1024 < 1 && <label style={{ marginLeft: 20 }}>{Math.round(ctx.documentUploadQueue[0]._file?.size / 1024)} {t('file_size_kb')}</label>}
                                                                </Grid>

                                                                <Grid item xs={12} >
                                                                    <span style={{ marginRight: '20px' }}>{t("label.document-type")}</span>
                                                                    {i18n.resolvedLanguage === 'fr' ? <span >{supportDoc.wsibForm?.formNameFr}</span> : <span>{supportDoc.wsibForm?.formNameEn}</span>}
                                                                </Grid>
                                                            </Grid>
                                                        </Paper>
                                                    </Grid>
                                                })}
                                            </Grid>
                                        </Paper>
                                    )
                                }
                            })}
                    </Grid>
                    }

                    {!serverError && <Grid item xs={12} style={{ padding: 1 }}>
                        <br role="presentation"/>
                        <Typography variant="body1" variantMapping={{ body1: 'div' }}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={ctx.sendConfirmationEmail} onChange={onSendConfirmationChanged} />} label={t("label.email")} />
                            </FormGroup>
                        </Typography>
                    </Grid>}

                    {!serverError && <Grid item xs={12} >
                        <br role="presentation" />
                        <Typography variant="body1" variantMapping={{ body1: 'div' }}>
                            <div><Typography variant="h2">{t('header.dob.declaration')}</Typography></div>
                        </Typography>
                    </Grid>}

                    {!serverError && <Grid item xs={12} >
                        <Paper variant="outlined" style={{ width: '95%', padding: 2, backgroundColor: '#F6F8FA' }} elevation={0}>
                            <Typography variant="body1" variantMapping={{ body1: 'div' }}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={ctx.acceptTermOfUse} onChange={onAcceptTermChanged} />}
                                        label={<span>
                                            <span style={{ color: '#333333' }}>{t("label.i.declare")}</span>
                                            {i18n.resolvedLanguage === 'en' ? <a className="terms-use-link" style={{ padding: 0 }} href="https://www.wsib.ca/en/terms-use" target="_blank" rel="noopener noreferrer">{t("term.of.use")}</a> :
                                                <a className="terms-use-link" style={{ padding: 0 }} href="https://www.wsib.ca/fr/modalites-dutilisation" target="_blank" rel="noopener noreferrer">{t("term.of.use")}</a>
                                            }
                                            <span style={{ color: '#333333' }}>{t("label.i.declare2")}</span>
                                        </span>}
                                    />
                                </FormGroup>
                            </Typography>
                        </Paper>
                        {(submitClicked && noAcceptTermOfUseError) && <FormHelperText style={{ marginTop: '0px' }} error={true}><span aria-live="assertive" role="alert">{t("confirm_term_of_use")}</span></FormHelperText>}
                        <p></p>
                    </Grid>}

                    {!serverError && ctx.showAlertMsg && (
                        <Grid item xs={12} className="row">
                            <div className="warningContainer">
                                <span className="warningIcon">
                                    <figure className="imageFigure"><img src="/assets/b2c/images/Alert.svg" alt="warning icon" className="infoIcon" id="Image" /></figure>
                                </span>
                                <span id="acnDescriptionSpan" className="warningText">
                                    {t("document-upload-addition-text1")}
                                    <Link href={t("document-upload-addition-link-href")} underline="hover" target="_blank">
                                        {t("document-upload-addition-link")}
                                    </Link>
                                    {t("document-upload-addition-text2")}
                                </span>
                            </div>
                        </Grid>
                    )}

                    {!serverError && <Grid item xs={12} >
                        <br role="presentation"/>
                        <br role="presentation"/>
                        <span aria-hidden="true"></span>&nbsp;<button className="navbtn" onClick={props.onPrev} aria-label={ctx.isUncd ? t("button.previous") + t("gotoLabel") + t("step4Label") : t("button.previous") + t("gotoLabel") + t("step3Label")}>{t("button.previous")}</button><button className="btn-wsib" type="submit" onClick={onNext} aria-label={ctx.isUncd ? t("label.next") + t("gotoLabel") + t("step6Label") : t("label.next") + t("gotoLabel") + t("step5Label")}>{t("label.submit")}</button> &nbsp;<span aria-hidden="true"></span>
                    </Grid>
                    }

                </Grid>
            </Container>
        </LocalizationProvider>
    );
}

export default Review;